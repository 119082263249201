<template>
  <div>
    <HeaderApp/>
    <main>
      <!--      If loaded = false then show text: Loading... at center screen-->
      <div v-if="!loaded" class="d-flex justify-content-center align-items-center" style="height: 80vh;">
        <h6>Loading...</h6>
      </div>
      <!--      Generate UI product detail with the information: product name, image, description, last update, status (sale, free), change log-->
      <div v-if="loaded" class="container">
        <div class="row">
          <div class="col-12 col md-6">
            <img :src="product.tool.image_url" alt="toolImage" class="img-fluid">
          </div>
          <div class="col-12 col md-6">
            <h2>{{ product.tool.name }}</h2>
            <p>{{ 'Version: ' + product.tool.last_version }}</p>
            <LabelApp :class="product.tool.is_free ? 'bg-success' : 'bg-warning'"
                      :text="product.tool.is_free ? 'Free' : 'Sale'"/>
            <p class="mt-3">{{ 'Last update: ' + product.tool.updated_at_convert }}</p>

            <a :href="product.versionTool.file_path" class="btn btn-primary">Download</a>

            <h3 class="mt-5">Description</h3>

            <!--            Description is just a paragraph text and have break line "\n" and border with radius-->
            <p class="p-3" style="border-radius: 0.5em; background-color: #f8f9fa;"
               v-html="product.tool.description.replace(/\n/g, '<br>')"></p>

            <h3 class="mt-5">Change log</h3>
            <!--            Change log is just a paragraph text and have break line "\n" and border with radius-->
            <p class="p-3" style="border-radius: 0.5em; background-color: #f8f9fa;"
               v-html="product.versionTool.change_log.replace(/\n/g, '<br>')"></p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderApp from '@/components/HeaderApp.vue';
import axios from "axios";
import LabelApp from "@/components/LabelApp.vue";
import router from "@/router";

export default {
  components: {
    LabelApp,
    HeaderApp
  },
  props: ['code'],
  data() {
    return {
      loaded: false,
      product: {
        tool: {
          updated_at_convert: '',
          is_free: 0,
          image_url: '',
          name: '',
          description: '',
          last_version: '',
          updated_at: ''
        },
        versionTool: {
          file_path: '',
          change_log: ''
        }
      }
    };
  },
  created() {
    axios.get('https://api.redftool.com/v1/tools/public/detail?tool_code=' + this.code)
        .then(response => {
          if (response.status !== 200) {
            router.push('/');
          }
          this.product = response.data.result;
          /*Convert timestamp updated_at to updated_at_convert yyyy-MM-dd hh:mm:ss*/
          this.product.tool.updated_at_convert = new Date(this.product.tool.updated_at).toLocaleString();
          this.loaded = true;
        })
        .catch(() => {
          router.push('/');
        });
  }
};
</script>
