<template>
  <label>{{ text }}
  </label>
</template>

<script>
export default {
  name: 'LabelApp',
  props: ['text']
};
</script>

<style scoped>
label {
  padding: 0.2em 0.6em;
  border-radius: 0.25em;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 0.8em;
}
</style>
