<template>
  <footer class="footer mt-auto py-3 bg-black">
    <div class="container">
      <p class="text-center text-white">© 2020 RedFTool</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterApp'
};
</script>

<style scoped>
</style>
