import {createRouter, createWebHistory} from 'vue-router';
import ProductList from '@/views/ProductList.vue';
import ProductDetail from '@/views/ProductDetail.vue';

const routes = [
    {path: '/', component: ProductList},
    {path: '/products/:code', component: ProductDetail, props: routes => ({code: routes.params.code})},
    {path: '/:notFound(.*)', redirect: '/'}
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
