<template>
  <div class="button-container">
    <div v-if="hasNotification">
      <button id="notification-btn" class="btn btn-light" data-bs-target="#notificationModal" data-bs-toggle="modal"
              type="button">
        <i class="bi bi-bell-fill"></i>
      </button>
      <div id="notificationModal" aria-hidden="true" aria-labelledby="notificationModalLabel" class="modal fade"
           tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="notificationModalLabel" class="modal-title">Notification</h5>
              <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
              <p v-html="content"></p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="btn btn-light" :href="urlJoinTelegram" target="_blank">
      <i class="bi bi-telegram"></i>
    </a>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: 'NotificationModal',
  props: [],
  data() {
    return {
      hasNotification: true,
      content: '',
      urlJoinTelegram: '#'
    };
  },
  created() {
    axios.get('https://api.redftool.com/v1/notification/web-all')
        .then(response => {
          let notifications = response.data.result;
          if (notifications.length > 0) {
            this.hasNotification = true;
            this.content = notifications[0].body;
            document.getElementById('notification-btn').click();
          } else {
            this.hasNotification = false;
          }
        })
        .catch(() => {
        });
    axios.get('https://api.redftool.com/v1/contacts')
        .then(response => {
          let contacts = [{id: 0, name: '', urlFb: ''}];
          if (response.data.result.length > 0) {
            contacts = response.data.result;
            for (let i = 0; i < contacts.length; i++) {
              if (contacts[i].name === '[Join Group]') {
                this.urlJoinTelegram = contacts[i].urlFb;
                break;
              }
            }
          }
        })
        .catch(() => {
        });
  }
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container > div,
.button-container > button {
  margin-right: 10px;
}
</style>