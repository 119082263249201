<template>
  <router-link :product="product" :to="'/products/' + product.code"
               style="text-decoration: none; color: inherit;">
    <div class="card h-100">
      <img :src="product.image_url" alt="toolImage"
           class="card-img-top">
      <div class="card-body">
        <h5 class="card-title">{{ product.name }}</h5>
        <p>{{ 'Version: ' + product.last_version }}</p>
        <LabelApp :class="product.is_free === 1 ? 'bg-success' : (product.is_free === 0 ? 'bg-warning' : 'bg-info') "
                  :text="product.is_free === 1 ? 'Free' : (product.is_free === 0 ? 'Sale' : 'Gift')"/>
      </div>
      <div class="card-footer">
        <small class="text-muted">{{ 'Last update: ' + product.updated_at_convert }}</small>
      </div>
    </div>
  </router-link>
</template>

<script>

import LabelApp from "@/components/LabelApp.vue";

export default {
  name: 'ItemProduct',
  props: [
    'product'
  ],
  components: {
    LabelApp
  }
};
</script>

<style scoped>
p {
  font-size: 0.8em;
  color: #6c757d;
}

/**
  Make card look beautiful with shadow + style + professional
 */
.card {
  border-radius: 0.5em;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.card-img-top {
  object-fit: cover;
  /* Make it look smaller than*/
  width: 60%;
  max-width: 150px;
  margin: 0 auto;
}
</style>