<template>
  <div id="app" class="d-flex flex-column vh-100">
    <router-view></router-view>
    <FooterApp/>
  </div>
</template>

<script>
import FooterApp from "@/components/FooterApp.vue";

export default {
  name: 'App',
  components: {FooterApp}
};
</script>
