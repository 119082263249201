<template>
  <header class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">RedFTool</a>
      <NotificationModal/>
    </div>
  </header>
</template>

<script>
import NotificationModal from "@/components/NotificationModal.vue";

export default {
  name: 'HeaderApp',
  components: {NotificationModal}
};
</script>

<style scoped>
</style>
