<template>
  <div>
    <HeaderApp/>
    <main>
      <div v-if="!loaded" class="d-flex justify-content-center align-items-center" style="height: 80vh;">
        <h6>Loading...</h6>
      </div>
      <div v-if="loaded" class="container">
        <div class="input-group mb-3 mt-3">
          <input aria-label="Search product" class="form-control" placeholder="Search product" type="text"
                 @input="filterProduct($event)">
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
          <div v-for="product in filters" :key="product.code" class="col">
            <ItemProduct :product="product"/>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderApp from '@/components/HeaderApp.vue';
import axios from "axios";
import ItemProduct from "@/components/ItemProduct.vue";

export default {
  components: {
    ItemProduct,
    HeaderApp,
  },
  data() {
    return {
      loaded: false,
      products: [{
        code: '',
        name: '',
        description: '',
        image_url: '',
        updated_at: '',
        updated_at_convert: ''
      }],
      filters: [{
        code: '',
        name: '',
        description: '',
        image_url: '',
        updated_at: '',
        updated_at_convert: ''
      }]
    };
  },
  created() {
    axios.get('https://api.redftool.com/v1/tools/all')
        .then(response => {
          this.products = response.data.result;
          /*Convert timestamp (product.updated_at) to yyyy-MM-dd hh:mm:ss */
          this.products.forEach(product => {
            product.updated_at_convert = new Date(product.updated_at).toLocaleString();
          });
          /* Sort by product.updated_at order by desc */
          this.products.sort((a, b) => b.updated_at - a.updated_at);
          this.filters = this.products;
          this.loaded = true;
        })
        .catch(() => {
        });
  },
  methods: {
    filterProduct(event) {
      const keyword = event.target.value.toLowerCase();
      this.filters = this.products.filter(product => {
        return product.name.toLowerCase().includes(keyword);
      });
    }
  }
};
</script>
